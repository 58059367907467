import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "./loader.service";
import {Router} from "@angular/router";
import {PopupComponent} from "../shared/popup/popup.component";
import {TERMINAL_CONFIG} from "../config/api";
import {KeyboardService} from "./keyboard.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TerminalService {
    config = TERMINAL_CONFIG;
    isUserPlaying = false;
    public isUserActivityIframe = new BehaviorSubject(null);

    constructor(private http: HttpClient,
                private loaderService: LoaderService,
                private keyboardService: KeyboardService,
                private popupComponent: PopupComponent,
                private router: Router) {
        this.isAppDevice();
    }

    checkLastUpdates() {
        return  this.http.post<any>(this.config.updatesLast, {});
    }

    detectUserActivity() {
        this.isUserActivityIframe.next(Math.round(+new Date()/1000)+Math.random());
    }

    isAppDevice() {
        var userAgent = window.navigator.userAgent;
        var userAgent = userAgent.toLowerCase();
        if (userAgent.indexOf('myapp/kiosk') !== -1) {
            localStorage.setItem('isApp', '1');
        }else{
            localStorage.setItem('isApp', '0');
        }

        // if (environment.frontEndTest) {
        //     localStorage.setItem('isApp', '1');
        // }
    }

    isTerminal() {
        return !!Number(localStorage.getItem('isApp'));
    }

}
