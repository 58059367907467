import * as tslib_1 from "tslib";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./loader.service";
import * as i2 from "@angular/common/http";
var CashappPayService = /** @class */ (function () {
    function CashappPayService(loaderService, http) {
        this.loaderService = loaderService;
        this.http = http;
        this.options = {
            redirectURL: window.location.href,
            referenceId: 'my-distinct-reference-id',
        };
        this.buttonOptions = {
            shape: 'semiround',
            width: 'full',
        };
        this.scriptId = 'cashAppScript';
        this.paymentResult = new BehaviorSubject(null);
    }
    CashappPayService.prototype.createCashAppPayButton = function (cashappInfo, amount) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var script;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                try {
                    script = document.createElement('script');
                    script.src = cashappInfo.mode === 'sandbox' ? "https://sandbox.web.squarecdn.com/v1/square.js" : "https://web.squarecdn.com/v1/square.js";
                    script.setAttribute('id', this.scriptId);
                    script.onload = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: 
                                // this.payments = Square.payments(cashappInfo.appId, cashappInfo.locationId);
                                // this.req = this.payments.paymentRequest({
                                //     countryCode: 'US',
                                //     currencyCode: 'USD',
                                //     total: {
                                //         amount: String(amount),
                                //         label: 'Total',
                                //     },
                                // });
                                return [4 /*yield*/, this.showCashAppPayButton(amount, cashappInfo)];
                                case 1:
                                    // this.payments = Square.payments(cashappInfo.appId, cashappInfo.locationId);
                                    // this.req = this.payments.paymentRequest({
                                    //     countryCode: 'US',
                                    //     currencyCode: 'USD',
                                    //     total: {
                                    //         amount: String(amount),
                                    //         label: 'Total',
                                    //     },
                                    // });
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    document.body.appendChild(script);
                }
                catch (e) {
                    this.destroyCashApp();
                }
                return [2 /*return*/];
            });
        });
    };
    CashappPayService.prototype.showCashAppPayButton = function (amount, cashappInfo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 3, , 4]);
                        if (this.cashAppPay) {
                            this.cashAppPay.destroy();
                            this.cashAppPay = null;
                        }
                        this.payments = Square.payments(cashappInfo.appId, cashappInfo.locationId);
                        this.req = this.payments.paymentRequest({
                            countryCode: 'US',
                            currencyCode: 'USD',
                            total: {
                                amount: String(amount),
                                label: 'Total',
                            },
                        });
                        _a = this;
                        return [4 /*yield*/, this.payments.cashAppPay(this.req, this.options)];
                    case 1:
                        _a.cashAppPay = _b.sent();
                        // if (!this.cashAppPay) {
                        this.cashAppPay.addEventListener('ontokenization', function (event) {
                            var tokenResult = event.detail.tokenResult;
                            _this.paymentResult.next(tokenResult);
                            _this.destroyCashApp();
                            // if (tokenResult.status === 'OK') {
                            //     cashAppPay.destroy();
                            // }
                        });
                        return [4 /*yield*/, this.cashAppPay.attach('#cash-app-pay', this.buttonOptions)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        this.destroyCashApp();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CashappPayService.prototype.isCashAppPayScripExist = function () {
        return document.querySelector("#" + this.scriptId);
    };
    CashappPayService.prototype.destroyCashApp = function (full) {
        if (full === void 0) { full = false; }
        if (full) {
            // if (document.querySelector(`#${this.scriptId}`)) {
            //     document.querySelector(`#${this.scriptId}`).remove();
            // }
            // if (document.querySelector(`#square-payments-cash-app-pay-script`)) {
            //     document.querySelector(`#square-payments-cash-app-pay-script`).remove();;
            // }
            //
            // if (document.querySelector(`#cash-app-pay`)) {
            //     document.querySelector(`#cash-app-pay`).innerHTML = "";
            // }
            //
            // document.querySelectorAll('iframe').forEach(iframe => {
            //     if (iframe.src.includes('squarecdn') ||
            //       iframe.src.includes('squareupsandbox') ||
            //       iframe.src.includes('cash.app')) {
            //         iframe.remove();
            //     }
            // })
        }
        if (this.cashAppPay) {
            this.cashAppPay.destroy();
            this.cashAppPay = null;
        }
    };
    CashappPayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CashappPayService_Factory() { return new CashappPayService(i0.ɵɵinject(i1.LoaderService), i0.ɵɵinject(i2.HttpClient)); }, token: CashappPayService, providedIn: "root" });
    return CashappPayService;
}());
export { CashappPayService };
