import { ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { USER_BALANCE_CONFIG } from "../../config/api";
import { BehaviorSubject } from "rxjs";
import { PopupBalanceComponent } from "../../shared/popup-balance/popup-balance.component";
import { POPUP_BALANCE_INCREASE_BALANCE_TYPE } from "../../shared/popup-balance/popup-balance.config";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../keyboard.service";
import * as i3 from "../../shared/popup/popup.component";
import * as i4 from "../../modules/auth/_services/authentication.service";
import * as i5 from "../loader.service";
var UserBalanceService = /** @class */ (function () {
    function UserBalanceService(http, resolver, keyboardService, popupComponent, authenticationService, loaderService) {
        this.http = http;
        this.resolver = resolver;
        this.keyboardService = keyboardService;
        this.popupComponent = popupComponent;
        this.authenticationService = authenticationService;
        this.loaderService = loaderService;
        this.config = USER_BALANCE_CONFIG;
        this.showModal = new BehaviorSubject({ show: false, type: null, canClose: true, isSessionStart: false });
    }
    UserBalanceService.prototype.ngOnInit = function () { };
    UserBalanceService.prototype.showGeneratePopup = function (container, type, canBeClosed, isSessionStart) {
        var _this = this;
        if (canBeClosed === void 0) { canBeClosed = true; }
        if (isSessionStart === void 0) { isSessionStart = true; }
        try {
            this.container.clear();
        }
        catch (e) {
        }
        this.container = container;
        var factory = this.resolver.resolveComponentFactory(PopupBalanceComponent);
        var popup = this.container.createComponent(factory);
        popup.instance.popupInfo.show = true;
        popup.instance.popupInfo.type = type;
        popup.instance.popupInfo.canClose = canBeClosed;
        popup.instance.popupInfo.isSessionStart = isSessionStart;
        // popup.instance.closePopupSuccess = () => {
        //   container.clear();
        //   this.repeatFunction();
        //   this.resetPopup();
        // };
        this.keyboardService.showKeyboard();
        document.body.querySelector('.senet-iframe').classList.add('is-blurred');
        popup.instance.closePopup = function () {
            _this.container.clear();
            _this.resetPopup();
            _this.keyboardService.hideKeyboard();
            document.body.querySelector('.senet-iframe').classList.remove('is-blurred');
        };
    };
    UserBalanceService.prototype.showPopup = function (type, canBeClosed, isSessionStart) {
        this.showModal.next({ show: true, type: type, canClose: canBeClosed, isSessionStart: isSessionStart });
    };
    UserBalanceService.prototype.closePopup = function () {
        try {
            this.container.clear();
        }
        catch (e) {
        }
        this.resetPopup();
        this.keyboardService.hideKeyboard();
        document.body.querySelector('.senet-iframe').classList.remove('is-blurred');
    };
    UserBalanceService.prototype.getDepositBalance = function () {
        this.loaderService.show();
        return this.http.post(this.config.getDepositBalance, {});
    };
    UserBalanceService.prototype.onDeposit = function (amount, token) {
        this.loaderService.show();
        return this.http.post(this.config.onDeposit, { amount: amount, token: token });
    };
    UserBalanceService.prototype.onRedeem = function () {
        this.loaderService.show();
        return this.http.post(this.config.onRedeem, {});
    };
    UserBalanceService.prototype.resetPopup = function () {
        this.showModal.next({ show: false, type: null, canClose: false, isSessionStart: false });
    };
    UserBalanceService.prototype.getUserDeposit = function () {
        var _this = this;
        this.getDepositBalance().subscribe(function (data) {
            _this.handleUserBalance(data, POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    UserBalanceService.prototype.handleUserBalance = function (data, type) {
        try {
            if (data.status) {
                if (type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit) {
                    this.showPopup(POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit, true);
                }
                else if (type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem) {
                    this.showPopup(POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem, true);
                }
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    UserBalanceService.prototype.handleIframeMessage = function (data) {
        if (data.showPopup) {
            if (data.showPopup === POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit) {
                this.handleUserBalance({ status: true }, POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit);
                // this.getUserDeposit();
            }
            else if (data.showPopup === POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem) {
                this.handleUserBalance({ status: true }, POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem);
            }
            else if (data.showPopup === POPUP_BALANCE_INCREASE_BALANCE_TYPE.login) {
                this.authenticationService.logout();
            }
            else if (data.showPopup === POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp) {
                this.showPopup(POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp, true);
            }
        }
    };
    UserBalanceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserBalanceService_Factory() { return new UserBalanceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i2.KeyboardService), i0.ɵɵinject(i3.PopupComponent), i0.ɵɵinject(i4.AuthenticationService), i0.ɵɵinject(i5.LoaderService)); }, token: UserBalanceService, providedIn: "root" });
    return UserBalanceService;
}());
export { UserBalanceService };
