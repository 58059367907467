import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../popup/popup.component";
import {PROJECT_MESSAGES} from "../../config/project";
import {LoaderService} from "../../service/loader.service";
import {UserBalanceService} from "../../service/users/userBalance.service";
import {POPUP_BALANCE_INCREASE_BALANCE_TYPE, PopupRewardsGameInfoModel} from "./popup-balance.config";
import {KeyboardService} from "../../service/keyboard.service";
import {GamesSenetService} from "../../service/games/gamesSenet.service";
import {ContentDisplayingService} from "../../service/content-displaying.service";
import {CHECKOUT_CHARGE_PAYMENT_TYPE} from "../charge-popup/charge-popup.config";


@Component({
    selector: 'app-popup-balance',
    templateUrl: './popup-balance.component.html',
    styleUrls: ['./popup-balance.component.scss']
})
export class PopupBalanceComponent implements OnInit {
    @Input() popupInfo: PopupRewardsGameInfoModel = {show: false, balance: null, type: null, canClose: true};
    @Input() cashAppShortName: string = '';
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() updateBalance: EventEmitter<any> = new EventEmitter();
    @Output() onGameSweep: EventEmitter<any> = new EventEmitter();

    balanceForm: FormGroup;
    POPUP_BALANCE_INCREASE_BALANCE_TYPE = POPUP_BALANCE_INCREASE_BALANCE_TYPE;

    showNFTPopup = false;
    NFTPopupData;

    unitsCost = 0;
    aiUnits = 0;
    aiUnitCost = 0;
    terms: string;
    cashappInfo;
    depositStep = 1;

    isTermsExpandedShown = false;

    @ViewChild('imageInput', {static: false}) imageInput;
    @ViewChild('termsAccept', {static: false}) termsAccept: ElementRef;
    constructor(private loaderService: LoaderService,
                private userBalanceService: UserBalanceService,
                private gamesSenetService: GamesSenetService,
                private popupComponent: PopupComponent,
                private keyboardService: KeyboardService,
                private contentDisplayingService: ContentDisplayingService,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.balanceForm = this.formBuilder.group({
            amount: [null, Validators.required],
            terms: [false, Validators.required],
        });
        if (this.popupInfo.type === this.POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit) {
            this.userBalanceService.getDepositBalance().subscribe(data => {
                try {
                    if (data.status) {
                        this.depositStep = 1;
                        this.terms = data.terms;
                        this.balanceForm.controls.terms.setValue(false);
                        this.cashappInfo = data.cashappInfo;

                        this.unitsCost = 0;
                        this.aiUnits = 0;
                        this.aiUnitCost = data.aiPrice;
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                        this.closePopup();
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                    this.closePopup();
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
                this.closePopup();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    closePopup() {
        this.close.emit(true);
    }

    sweepGame() {
        this.closePopup();
        this.gamesSenetService.getSenetSessionRequest();
    }

    playGame() {
        this.closePopup();
        if (!this.popupInfo.isSessionStart) {
            this.gamesSenetService.getSenetSessionRequest();
        }
    }

    openNFTPopup($event) {
        this.showNFTPopup = $event.showNFTPopup;
        this.NFTPopupData = $event.data;
    }

    onPopupNFTClose() {
        this.showNFTPopup = false;
        // if (this.popupInfo.isSessionStart) {
        //     this.closePopup();
        // }
    }

    onDepositConfirm($event) {
        const amount = $event.amount;
        const token = $event.token;

        this.userBalanceService.onDeposit(amount, token).subscribe(data => {
            this.displaySubmitDepositConfirm(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    displaySubmitDepositConfirm(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.depositStep = 1;
                this.updateBalance.emit();
                this.closePopup();
                this.sweepGame();
            }else{
                this.popupComponent.showPopupError({text: data.message});
                this.loaderService.hide();
                this.closePopup();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    install() {
        this.userBalanceService.onRedeem().subscribe(data => {
            try {
                if (data.status) {
                    this.popupComponent.showPopupSuccess({
                        text: data.message,
                        confirmButtonText:'OK'
                    });
                    this.closePopup();
                } else {
                    this.popupComponent.showPopupError({
                        text: data.message,
                        confirmButtonText:'OK'
                    });
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    showAdditionContent() {
        return this.contentDisplayingService.showAdditionContent();
    }

    onUpdateUnits(val?) {
        if (val) {
            this.unitsCost = +this.unitsCost + val;
        } else {
            this.unitsCost = 0;
        }
        this.aiUnits = +this.unitsCost / +this.aiUnitCost;
    }

    goToSecondDepositStep() {
        document.querySelector('.balance-popup').scrollTo({top: 0, behavior: 'smooth'});
        this.depositStep = 2;
        this.onInitNFTPopup();
    }

    goToFirstDepositStep() {
        document.querySelector('.balance-popup').scrollTo({top: 0, behavior: 'smooth'});
        this.depositStep = 1;
        this.balanceForm.controls.terms.setValue(false);
    }

    showCheckoutTerms() {
        document.body.classList.add('hide-menu');
        this.isTermsExpandedShown = true;
    }

    closeTermsExpanded($event) {
        document.body.classList.remove('hide-menu');
        this.isTermsExpandedShown = false;
    }

    onSubmit() {
        const amount = this.unitsCost;

        if (amount) {
            this.onShowNFTPopup();
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.unknownError});
        }
    }

    onShowNFTPopup() {
        this.showNFTPopup = true;
        this.openNFTPopup({showNFTPopup: true, data: this.NFTPopupData});
    }

    onInitNFTPopup() {
        const inputAmount = this.unitsCost;

        this.NFTPopupData = {
            data: {
                payment: CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp,
                inputAmount: inputAmount
            }
        };
        this.NFTPopupData.data.cashappInfo = this.cashappInfo;
    }
}
