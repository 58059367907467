/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./charge-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./charge-popup.component";
import * as i4 from "../../service/loader.service";
import * as i5 from "../../service/cashapp-pay.service";
import * as i6 from "../popup/popup.component";
var styles_ChargePopupComponent = [i0.styles];
var RenderType_ChargePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChargePopupComponent, data: {} });
export { RenderType_ChargePopupComponent as RenderType_ChargePopupComponent };
function View_ChargePopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "nft-terms__text"], ["style", "line-height: 1.2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Click "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["style", "font-family: b-montserrat, sans-serif"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cash App Pay"])), (_l()(), i1.ɵted(-1, null, [" button below to deposit. "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" All sales are final. "]))], null, null); }
function View_ChargePopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["id", "payment-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["id", "cash-app-pay"]], null, null, null, null, null))], null, null); }
function View_ChargePopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "investments-popup"]], [[2, "is-active", null], [2, "is-blurred", null], [2, "is-accent", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "investments-popup__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "button", [["class", "investments-popup__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "nft-info"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChargePopupComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "nft-info__btns"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChargePopupComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.popupData.payment === _co.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.popupData.payment === _co.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.popupData && _co.show); var currVal_1 = false; var currVal_2 = false; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ChargePopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { imageInput: 0 }), i1.ɵqud(671088640, 2, { termsAccept: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChargePopupComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupData; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ChargePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-charge-popup", [], null, null, null, View_ChargePopupComponent_0, RenderType_ChargePopupComponent)), i1.ɵdid(1, 9158656, null, 0, i3.ChargePopupComponent, [i4.LoaderService, i1.ChangeDetectorRef, i5.CashappPayService, i6.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChargePopupComponentNgFactory = i1.ɵccf("app-charge-popup", i3.ChargePopupComponent, View_ChargePopupComponent_Host_0, { show: "show", popupData: "popupData" }, { close: "close", emitDepositConfirm: "emitDepositConfirm" }, []);
export { ChargePopupComponentNgFactory as ChargePopupComponentNgFactory };
