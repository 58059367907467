import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthConfig } from './auth-config';
import { AUTH_CONFIG } from '../../../config/api';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "./auth-config";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../service/loader.service";
import * as i4 from "../user-idle/user-idle.service";
import * as i5 from "@angular/router";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(authConfig, http, loaderService, userIdle, router) {
        this.authConfig = authConfig;
        this.http = http;
        this.loaderService = loaderService;
        this.userIdle = userIdle;
        this.router = router;
        this.config = AUTH_CONFIG;
        this.storage = sessionStorage;
        this.rolesObservable = new EventEmitter();
        this.userLoggedIn = new EventEmitter();
        this.userLoggedStatusChanged = new BehaviorSubject(this.isUserLogged());
    }
    AuthenticationService.prototype.isUserLogged = function () {
        return !!this.storage.getItem('currentUser');
    };
    AuthenticationService.prototype.sendEmail = function (email) {
        var _this = this;
        this.loaderService.show();
        var body;
        if (environment.frontEndTest) {
            body = { phone: '1234567890', country: 'us' };
        }
        else {
            body = { email: email };
        }
        return this.http.post(this.config.sendEmail, body)
            .pipe(map(function (res) {
            if (environment.frontEndTest) {
                res.promoCode = true;
            }
            if (res.status && !res.promoCode && res.result) {
                _this.setData(res.result);
                _this.userLoggedIn.emit(true);
                _this.userLoggedStatusChanged.next(true);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.register = function (email, promoCode) {
        var _this = this;
        this.loaderService.show();
        var body;
        if (environment.frontEndTest) {
            body = { phone: '1234567890', country: 'us', pin: '1111', promoCode: promoCode };
        }
        else {
            body = { email: email, promoCode: promoCode };
        }
        return this.http.post(this.config.register, body).pipe(map(function (res) {
            if (res.status) {
                _this.setData(res.result);
                _this.userLoggedIn.emit(true);
                _this.userLoggedStatusChanged.next(true);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.logoutRequest = function () {
        return this.http.post(this.config.logout, {});
    };
    AuthenticationService.prototype.setData = function (result) {
        this.storage = sessionStorage;
        this.storage.setItem('currentUser', JSON.stringify(result));
    };
    AuthenticationService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var iframeWrapper, iframe;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.storage.getItem('currentUser')) {
                            this.logoutRequest().toPromise();
                        }
                        this.storage.removeItem('currentUser');
                        return [4 /*yield*/, this.checkSession()];
                    case 1:
                        _a.sent();
                        this.userLoggedIn.emit(false);
                        this.userLoggedStatusChanged.next(false);
                        this.router.navigate(['./']);
                        iframeWrapper = document.querySelector('.senet-iframe');
                        iframe = document.querySelector('.senet-iframe iframe');
                        iframeWrapper.classList.remove('is-active');
                        iframe.removeAttribute('src');
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.prototype.setStamp = function () {
        var now = Date.now();
        this.authStamp = now;
        var $this = this;
        setTimeout(function () {
            $this.setStamp();
        }, 1000);
    };
    AuthenticationService.prototype.checkStamp = function () {
        if (this.storage.getItem('currentUser')) {
            var now = Date.now();
            if (this.authStamp) {
                if (now > (this.authStamp + 3000000)) {
                    this.logout();
                    this.router.navigate(['/']);
                    return;
                }
                else {
                    this.refreshTokenNow();
                }
            }
            else {
                this.refreshTokenNow();
            }
        }
    };
    AuthenticationService.prototype.refreshTokenNow = function () {
        var _this = this;
        if (this.storage.getItem('currentUser')) {
            this.http.post(this.config.refresh, {}).subscribe(function (res) {
                if (res.status) {
                    _this.setData(res.result);
                }
                else {
                    _this.router.navigate(['/']);
                }
                return res;
            });
        }
        return false;
    };
    AuthenticationService.prototype.refreshToken = function () {
        var $this = this;
        setTimeout(function () {
            $this.refreshToken();
        }, 60 * 5 * 1000);
        this.refreshTokenNow();
    };
    AuthenticationService.prototype.checkSession = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    AuthenticationService.prototype.getCurrentUser = function () {
        if (sessionStorage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                return currentUser;
            }
            catch (e) {
            }
        }
        return false;
    };
    AuthenticationService.prototype.getToken = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.token;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getRole = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.role_id;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getPointCountryId = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                if (currentUser.point_country_id) {
                    return currentUser.point_country_id;
                }
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getGameVendorShortName = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                if (currentUser.game_vendor_short_name) {
                    return currentUser.game_vendor_short_name;
                }
            }
            catch (e) { }
        }
        return '';
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.LoaderService), i0.ɵɵinject(i4.UserIdleService), i0.ɵɵinject(i5.Router)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
