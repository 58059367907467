import {DOMAIN_API} from "./local_settings";
import {DOMAIN_DEMO_API,DOMAIN_DEMO_NAME} from "./demo_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {environment} from "../../environments/environment";

let domainApiUrl = DOMAIN_API;
if(window.location.hostname == DOMAIN_DEMO_NAME){
    domainApiUrl = DOMAIN_DEMO_API;
}

let API = domainApiUrl;
let API_SENETPRO = API + '/senetpro';

if (environment.frontEndTest) {
    API = DOMAIN_API  + '/client';
    API_SENETPRO = DOMAIN_API  + '/client';
}

const APPS = API_SENETPRO + '/apps';
const LP = APPS + '/lp';

const USERS = API_SENETPRO + '/users';
const TERMINALS = API_SENETPRO + '/terminals';
const DEPOSIT = TERMINALS + '/deposit';
const CHECKOUT = API_SENETPRO + '/checkout';

export const AUTH_CONFIG: AuthConfig = {
    sendEmail: LP + '/auth',
    register: LP + '/register',

    logout: USERS + '/session/logout',
    refresh: USERS + '/session/refresh'
};

if (environment.frontEndTest) {
    AUTH_CONFIG.sendEmail = USERS + '/phone/check';
    AUTH_CONFIG.register = USERS + '/session/login';
}

export const APP_VERSION_CONFIG = {
    version: LP + '/version',
};

export const USER_BALANCE_CONFIG = {
    getDepositBalance:  LP + '/settings',
    onDeposit: LP + '/deposit',
    onRedeem: LP + '/install'
}
if (environment.frontEndTest) {
    USER_BALANCE_CONFIG.getDepositBalance = API + '/jsons/find/file?name=senetTerminalGetDepositInfo';
    USER_BALANCE_CONFIG.onDeposit = API + '/jsons/find/file?name=success';
    USER_BALANCE_CONFIG.onRedeem = API_SENETPRO + '/jsons/find/file?name=success';
}

export const GAMES_SENET_CONFIG = {
    getSenetSession: API + '/games/senet/session/terminal',
    // getSenetSession: API + '/games/senet/session/create',
}
if (environment.frontEndTest) {
    GAMES_SENET_CONFIG.getSenetSession = API + '/jsons/find/file?name=getSenetSession';
}

export const TERMINAL_CONFIG = {
    updatesLast: LP + '/last',
};
if (environment.frontEndTest) {
    TERMINAL_CONFIG.updatesLast = API + '/jsons/find/file?name=updates';
}
