import {Injectable} from '@angular/core';
import {LoaderService} from "./loader.service";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";

declare var Square: any;

@Injectable({
    providedIn: 'root'
})
export class CashappPayService {
    payments;
    req;
    options = {
        redirectURL: window.location.href,
        referenceId: 'my-distinct-reference-id',
    };
    buttonOptions = {
        shape: 'semiround',
        width: 'full',
    };
    scriptId = 'cashAppScript';

    paymentResult = new BehaviorSubject(null);
    cashAppPay;
    constructor(public loaderService: LoaderService,
                private http: HttpClient) {}

    async createCashAppPayButton(cashappInfo, amount) {
        try {
            const script = document.createElement('script');
            script.src = cashappInfo.mode === 'sandbox' ? "https://sandbox.web.squarecdn.com/v1/square.js" : "https://web.squarecdn.com/v1/square.js";
            script.setAttribute('id', this.scriptId);
            script.onload = async () => {
                // this.payments = Square.payments(cashappInfo.appId, cashappInfo.locationId);
                // this.req = this.payments.paymentRequest({
                //     countryCode: 'US',
                //     currencyCode: 'USD',
                //     total: {
                //         amount: String(amount),
                //         label: 'Total',
                //     },
                // });
                await this.showCashAppPayButton(amount, cashappInfo);
            }
            document.body.appendChild(script);
        } catch (e) {
            this.destroyCashApp();
        }

    }

    async showCashAppPayButton(amount, cashappInfo) {
        try {
            if (this.cashAppPay) {
                this.cashAppPay.destroy();
                this.cashAppPay = null;
            }
            this.payments = Square.payments(cashappInfo.appId, cashappInfo.locationId);
            this.req = this.payments.paymentRequest({
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    amount: String(amount),
                    label: 'Total',
                },
            });
            this.cashAppPay = await this.payments.cashAppPay(this.req, this.options);
            // if (!this.cashAppPay) {

                this.cashAppPay.addEventListener('ontokenization', (event) => {
                    const { tokenResult } = event.detail;
                    this.paymentResult.next(tokenResult);
                    this.destroyCashApp();
                    // if (tokenResult.status === 'OK') {
                    //     cashAppPay.destroy();
                    // }
                });
                await this.cashAppPay.attach('#cash-app-pay', this.buttonOptions);
            // }
        } catch (e) {
            this.destroyCashApp();
        }
    }

    isCashAppPayScripExist() {
        return document.querySelector(`#${this.scriptId}`);
    }

    destroyCashApp(full = false) {
        if (full) {
            // if (document.querySelector(`#${this.scriptId}`)) {
            //     document.querySelector(`#${this.scriptId}`).remove();
            // }
            // if (document.querySelector(`#square-payments-cash-app-pay-script`)) {
            //     document.querySelector(`#square-payments-cash-app-pay-script`).remove();;
            // }
            //
            // if (document.querySelector(`#cash-app-pay`)) {
            //     document.querySelector(`#cash-app-pay`).innerHTML = "";
            // }
            //
            // document.querySelectorAll('iframe').forEach(iframe => {
            //     if (iframe.src.includes('squarecdn') ||
            //       iframe.src.includes('squareupsandbox') ||
            //       iframe.src.includes('cash.app')) {
            //         iframe.remove();
            //     }
            // })
        }
        if (this.cashAppPay) {
            this.cashAppPay.destroy();
            this.cashAppPay = null;
        }
    }
}
