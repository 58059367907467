import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    AfterViewInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren, HostListener, Injectable
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {LoginService} from "../../service/login.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {slideToRight} from "../../animations/router.animations";
import {LoaderService} from "../../service/loader.service";
import {RememberUserService} from "../../service/rememberUser.service";
import {PHONE_MASK} from "../../config/country_phone_mask";
import {KeyboardService} from "../../service/keyboard.service";
import {AUTH_CODES} from "../../config/auth_codes";
import {UserBalanceService} from "../../service/users/userBalance.service";
import {POPUP_BALANCE_INCREASE_BALANCE_TYPE} from "../../shared/popup-balance/popup-balance.config";
import {GamesSenetService} from "../../service/games/gamesSenet.service";
import {TerminalService} from "../../service/terminal.service";
import {ScreenSaverService} from "../../service/screen-saver.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [slideToRight],
    host: {'[@slideToRight]': ''}
})
@Injectable({providedIn: 'root'})
export class LoginComponent implements OnInit {
    isTerminal = !!Number(localStorage.getItem('isApp'));

    promoCodeForm: FormGroup;
    emailForm: FormGroup;

    showEmailPopup = true;
    isPromoCodeRequire = false;

    logoutTimeout;
    logoutAfterMin = 2;
    logoutAfterSec = this.logoutAfterMin * 60;
    logoutAutoConfirmAfterSec = 20;


    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    isKeyboard = false;
    logoutInterval;
    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private loginService: LoginService,
        private router: Router,
        private auth:AuthenticationService,
        private loaderService: LoaderService,
        private keyboardService: KeyboardService,
        private rememberUserService: RememberUserService,
        private userBalanceService: UserBalanceService,
        private gamesSenetService: GamesSenetService,
        private terminalService: TerminalService,
        private screenSaverService: ScreenSaverService,
        private popupComponent: PopupComponent) {

        if (this.terminalService.isTerminal()) {
            this.detectActivity();
            this.setLogoutTimer();
            this.screenSaverService.setSaveScreenTimer();

            this.terminalService.isUserActivityIframe.subscribe(val => {
                if (val) {
                    this.setLogoutTimer();
                }
            });

            // this.screenSaverService.logoutCmd.subscribe(val => {
            //     if (val) {
            //         this.logoutSinglePage();
            //     }
            // })
        }

        this.keyboardService.isKeyboardVisible.subscribe(value => {
            this.isKeyboard = value;
        });

        this.authenticationService.userLoggedStatusChanged.subscribe(val => {
            if (val) {

            } else {
                this.showEmailPopup = true;
                this.emailForm = this.formBuilder.group({
                    email: [null, Validators.required],
                });

                this.promoCodeForm = this.formBuilder.group({
                    promoCode: [null],
                });
                this.userBalanceService.closePopup();
                this.promoCodeForm.reset();

                if (this.terminalService.isTerminal()) {
                    this.setLogoutTimer();
                    this.screenSaverService.setSaveScreenTimer();
                }

                if (this.isKeyboard) {
                    this.keyboardService.showKeyboard();
                }
                setTimeout(() => {
                    // this.inputs.toArray()[0].nativeElement.focus();
                    (document.querySelector('input') as HTMLInputElement).focus();
                    if (this.isKeyboard) {
                        this.keyboardService.setActiveInput();
                    }
                }, 0);

            }
        });
    }
    ngOnInit() {
        this.isTerminal = this.terminalService.isTerminal();
        this.auth.logout();
        this.showEmailPopup = true;

        this.emailForm = this.formBuilder.group({
            email: [null, Validators.required]
        });

        this.promoCodeForm = this.formBuilder.group({
            promoCode: [null, Validators.required]
        });
    }

    ngOnDestroy(){
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.clearSaveScreenTimer();
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
            }
        }
    }

    detectActivity() {
        setTimeout(() => {
            document.addEventListener('scroll', this.onUserActivity.bind(this));
            document.addEventListener('touchstart', this.onUserActivity.bind(this));
            document.addEventListener('click', this.onUserActivity.bind(this));
            document.addEventListener('keydown', this.onUserActivity.bind(this));
        });
    }

    onUserActivity() {
        this.setLogoutTimer();
        if (this.showEmailPopup) {
            this.screenSaverService.hideScreenSaver();
            setTimeout(() => {
                (document.querySelector('input') as HTMLInputElement).focus();
                if (this.isKeyboard) {
                    this.keyboardService.setActiveInput();
                }
            }, 0);
        } else {
            this.screenSaverService.clearSaveScreenTimer();
        }
    }

    setLogoutTimer() {
        if (this.terminalService.isTerminal()) {
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
                clearInterval(this.logoutInterval);
            }
            this.logoutTimeout = setTimeout(() => {
                if (this.showEmailPopup) {
                    if (this.emailForm.controls.email.value) {
                        this.emailForm.controls.email.setValue('');
                        this.popupComponent.closePopup();
                        this.screenSaverService.setSaveScreenTimer();
                    }
                    // this.updateLogoutTimer();
                    this.setLogoutTimer();

                } else if (this.terminalService.isUserPlaying) {
                    this.terminalService.checkLastUpdates().subscribe(data => {
                        const now = data.now;
                        const last = data.last;
                        if ((now - last) > this.logoutAfterSec ) {
                            this.logout();
                        }
                    }, () => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        // this.updateLogoutTimer();
                        this.setLogoutTimer();
                    });
                } else {
                    this.logout();
                }
            }, this.logoutAfterSec * 1000);
            // if (this.logoutTimeout) {
            //     let count = 0;
            //     this.logoutInterval = setInterval(() => {
            //         count += 1;
            //         console.log(this.logoutTimeout, count)
            //     }, 1000);
            // }
        }

    }

    logout() {
        let counter = this.logoutAutoConfirmAfterSec;
        const counterInterval = setInterval(() => {
            if (counter) {
                counter -= 1;
                document.querySelector('#logoutTimer').innerHTML = String(counter) + '.';
            } else {
                clearInterval(counterInterval)
            }
        }, 1000);

        const logoutTime = this.logoutAfterMin === 5 ? 'five (5)' : this.logoutAfterMin === 2 ? 'two (2)' : '';
        this.popupComponent.showPopupAgreement({text: `Are you still here? No activity is detected for ${logoutTime} minutes. We are about to log you out in <span id="logoutTimer">${counter}</span>`, style: 'swal-agreement no-close-btn btn-continue'}, (result) => {
            if (result === 'timerOut') {
                this.logoutSinglePage();
            }
            clearInterval(counterInterval);
            this.setLogoutTimer();
            this.screenSaverService.setSaveScreenTimer();
        }, this.logoutAutoConfirmAfterSec * 1000);
    }

    logoutSinglePage() {
        this.popupComponent.closePopup();

        this.terminalService.isUserPlaying = false;
        this.showEmailPopup = true;
        this.isPromoCodeRequire = false;

        if (this.promoCodeForm && this.emailForm) {
            this.emailForm.controls.email.setValue('');
            this.promoCodeForm.reset();
        }

        this.authenticationService.logout();
        this.userBalanceService.closePopup();

        setTimeout(() => {
            // this.inputs.toArray()[0].nativeElement.focus();
            (document.querySelector('input') as HTMLInputElement).focus();
            if (this.isKeyboard) {
                this.keyboardService.setActiveInput();
            }
        }, 0);

    }

    updateLogoutTimer() {
        console.log('updateLogoutTimer : sliders');
        this.logoutTimeout = setTimeout(() => {
            this.setLogoutTimer();
        }, this.logoutAfterSec * 1000);
    }

    onEmailSubmit() {
        if (this.emailForm.invalid) {
            return;
        }

        const email = this.emailForm.controls.email.value;

        this.authenticationService.sendEmail(email).subscribe(data => {
            // data.status = false;
            // data.promoCode = 1;
            try {
                if (data.status) {
                    if (data.promoCode) {
                        this.showEmailPopup = false;
                        this.isPromoCodeRequire = true;

                        setTimeout(() => {
                            (document.querySelector('input') as HTMLInputElement).focus();
                            if (this.isKeyboard) {
                                this.keyboardService.setActiveInput();
                            }
                        }, 0);
                        if (this.terminalService.isTerminal()) {
                            this.screenSaverService.clearSaveScreenTimer();
                        }
                    } else {
                        this.checkLoginSubmit(data);
                    }
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }

        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onRegisterNewUser() {
        let email = this.emailForm.controls.email.value;
        let promoCode = this.promoCodeForm.controls.promoCode.value;

        if (promoCode) {
          promoCode = String(promoCode).toLowerCase();
        }

        this.authenticationService.register(email, promoCode).subscribe(data => {
            try {
                if (data.status) {
                    this.checkLoginSubmit(data);
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }

        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkLoginSubmit(data) {
        try {
            if (data.status) {
                this.createSenetGamesSession();
                this.showEmailPopup = false;
                this.isPromoCodeRequire = false;
                if (this.isKeyboard) {
                    this.keyboardService.keyboardVisible.next(false);
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
                //this.popupGoBack();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
            //this.popupGoBack();
        }
    }

    createSenetGamesSession() {
        this.gamesSenetService.getSenetSessionRequest();
    }

    popupGoBack() {
        this.showEmailPopup = true;
        this.isPromoCodeRequire = false;
        this.promoCodeForm.reset();
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.setSaveScreenTimer();
        }
        setTimeout(() => {
            (document.querySelector('input') as HTMLInputElement).focus();
            if (this.isKeyboard) {
                this.keyboardService.setActiveInput();
            }
        }, 1000);
    }
}

