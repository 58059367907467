import * as i0 from "@angular/core";
var ContentDisplayingService = /** @class */ (function () {
    function ContentDisplayingService() {
        this.domainsLessContent = [];
    }
    ContentDisplayingService.prototype.detectDomain = function () {
        var host = window.location.host;
        // const host = 'mex.dkdk.com';
        // const host = 'fr.dkdk.com';
        return host.split('.')[0];
        //const subdomain = 'mx';
    };
    ContentDisplayingService.prototype.showAdditionContent = function () {
        return !this.domainsLessContent.includes(this.detectDomain());
    };
    ContentDisplayingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContentDisplayingService_Factory() { return new ContentDisplayingService(); }, token: ContentDisplayingService, providedIn: "root" });
    return ContentDisplayingService;
}());
export { ContentDisplayingService };
