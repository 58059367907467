import {ComponentFactory, ComponentFactoryResolver, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";
import {USER_BALANCE_CONFIG} from "../../config/api";
import {BehaviorSubject} from "rxjs";
import {PopupBalanceComponent} from "../../shared/popup-balance/popup-balance.component";
import {KeyboardService} from "../keyboard.service";
import {DomSanitizer} from "@angular/platform-browser";
import {PopupComponent} from "../../shared/popup/popup.component";
import {GamesSenetService} from "../games/gamesSenet.service";
import {POPUP_BALANCE_INCREASE_BALANCE_TYPE} from "../../shared/popup-balance/popup-balance.config";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class UserBalanceService {
  private config = USER_BALANCE_CONFIG;
  container;
  showModal = new BehaviorSubject({show: false, type: null, canClose: true, isSessionStart: false});
  constructor(private http: HttpClient,
              private resolver: ComponentFactoryResolver,
              private keyboardService: KeyboardService,
              private popupComponent: PopupComponent,
              private authenticationService: AuthenticationService,
              public loaderService: LoaderService) {
  }

  ngOnInit() {}

  showGeneratePopup(container, type, canBeClosed = true, isSessionStart = true) {
    try {
      this.container.clear();
    } catch (e) {
      
    }
    this.container = container;
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(PopupBalanceComponent);
    const popup = this.container.createComponent(factory);
    popup.instance.popupInfo.show = true;
    popup.instance.popupInfo.type = type;
    popup.instance.popupInfo.canClose = canBeClosed;
    popup.instance.popupInfo.isSessionStart = isSessionStart;
    // popup.instance.closePopupSuccess = () => {
    //   container.clear();
    //   this.repeatFunction();
    //   this.resetPopup();
    // };
    this.keyboardService.showKeyboard();
    document.body.querySelector('.senet-iframe').classList.add('is-blurred');
    popup.instance.closePopup = () => {
      this.container.clear();
      this.resetPopup();
      this.keyboardService.hideKeyboard();
      document.body.querySelector('.senet-iframe').classList.remove('is-blurred');
    };
  }

  showPopup(type, canBeClosed, isSessionStart?) {
    this.showModal.next({show: true, type: type, canClose: canBeClosed, isSessionStart: isSessionStart});
  }

  closePopup() {
    try {
      this.container.clear();
    } catch (e) {

    }
    this.resetPopup();
    this.keyboardService.hideKeyboard();
    document.body.querySelector('.senet-iframe').classList.remove('is-blurred');
  }

  getDepositBalance() {
    this.loaderService.show();
    return this.http.post<any>(this.config.getDepositBalance, {});
  }

  onDeposit(amount, token) {
    this.loaderService.show();
    return this.http.post<any>(this.config.onDeposit, {amount, token});
  }

  onRedeem() {
    this.loaderService.show();
    return this.http.post<any>(this.config.onRedeem, {});
  }

  resetPopup() {
    this.showModal.next({show: false, type: null, canClose: false, isSessionStart: false});
  }

  getUserDeposit() {
    this.getDepositBalance().subscribe(data => {
      this.handleUserBalance(data, POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit);
    }, () => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  handleUserBalance(data, type) {
    try {
      if (data.status) {
        if (type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit) {
          this.showPopup(POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit, true);
        } else if (type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem) {
          this.showPopup(POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem, true);
        }
      } else {
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  handleIframeMessage(data) {
    if (data.showPopup) {
      if (data.showPopup === POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit) {
        this.handleUserBalance({status: true}, POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit);
        // this.getUserDeposit();
      } else if (data.showPopup === POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem) {
        this.handleUserBalance({status: true}, POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem);
      } else if (data.showPopup === POPUP_BALANCE_INCREASE_BALANCE_TYPE.login) {
        this.authenticationService.logout();
      } else if (data.showPopup === POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp) {
        this.showPopup(POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp, true);
      }
    }
  }
}
