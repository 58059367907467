import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContentDisplayingService {
  domainsLessContent = [];

  constructor() {}

  detectDomain() {
    const host = window.location.host;
    // const host = 'mex.dkdk.com';
    // const host = 'fr.dkdk.com';
    return host.split('.')[0];
    //const subdomain = 'mx';
  }

  showAdditionContent() {
    return !this.domainsLessContent.includes(this.detectDomain());
  }
}
