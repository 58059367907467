<div class="investments-popup investments-popup--balance"
     [class.is-blurred]="showNFTPopup"
     [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner">
        <button class="investments-popup__close"
                *ngIf="popupInfo.canClose"
                (click)="closePopup()"></button>
        <div class="balance-popup">
            <div class="balance-popup__content">
                <div *ngIf="popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit; else redeemTitle">
                    <div class="gl-page-title gl-page-title--icon c-accent">
                        <svg>
                            <use xlink:href="../../../../assets/images/sprite.svg#coin-icon"></use>
                        </svg>
                        Create Your NFT
                    </div>
                    <div class="gl-subtitle gl-subtitle--opacity" style="text-align: left; color: #fff">
                        <ng-container *ngIf="depositStep === 1">
                            You are about to create a collection of AI Generated Images.
                            <ng-container *ngIf="showAdditionContent()">
                                <span class="c-accent-addition">
                                    AND get FREE Sweeps on the platforms of your choosing!
                                </span>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="depositStep === 2">
                            Great Job! You are just one step away from creating your own images for NFT Minting
                            <ng-container *ngIf="showAdditionContent()">
                                <span class="c-accent-addition">
                                    and FREE Sweeps Entries
                                </span>
                            </ng-container>
                            .
                        </ng-container>
                    </div>

                    <ng-container *ngIf="depositStep === 1">
                        <div class="balance-heading">
                            <div class="balance-heading__col">
                                <div class="balance-heading__row">
                                    <div class="gl-text">
                                        AI UNITS:
                                    </div>
                                    <div class="gl-text">{{aiUnits}} UNITS</div>
                                </div>
                                <div class="balance-heading__row">
                                    <div class="gl-text">
                                        Sweeps Entries:
                                    </div>
                                    <div class="gl-text">{{unitsCost | currency}}</div>
                                </div>
                            </div>
                        </div>
                        <ul class="units-selection">
                            <li class="units-selection__item">
                                <button class="units-selection__btn"
                                        (click)="onUpdateUnits(5)">5</button>
                            </li>
                            <li class="units-selection__item">
                                <button class="units-selection__btn"
                                        (click)="onUpdateUnits(10)">10</button>
                            </li>
                            <li class="units-selection__item">
                                <button class="units-selection__btn"
                                        (click)="onUpdateUnits(20)">20</button>
                            </li>
                            <li class="units-selection__item">
                                <button class="units-selection__btn"
                                        (click)="onUpdateUnits(50)">50</button>
                            </li>
                            <li class="units-selection__item">
                                <button class="units-selection__btn"
                                        (click)="onUpdateUnits()">
                                    <img src="../../../assets/images/clear-symbol.png" alt="">
                                </button>
                            </li>
                        </ul>
                    </ng-container>

                    <ng-container *ngIf="depositStep === 2">
                        <form [formGroup]="balanceForm" class="login__form">
                            <div class="checkout-terms">
                                <div class="checkout-terms__title">
                                    Terms & Conditions
                                </div>

                                <div class="checkout-terms__text">
                                    <div class="nft-terms" [innerHTML]="terms"></div>
                                </div>

                                <div style="text-align: center; box-shadow: 0 -1rem 1rem 1rem rgba(43, 43, 43, 1); position: relative;">
                                    <button class="button"
                                            (click)="showCheckoutTerms()">
                                        expand
                                    </button>
                                </div>
                            </div>
                            <div class="nft-terms-agreement" style="margin-bottom: 2rem">
                                <input #termsAccept
                                       id="termsAccept"
                                       formControlName="terms"
                                       class="nft-terms-agreement__input checkbox-primary"
                                       type="checkbox">
                                <label class="nft-terms-agreement__label" for="termsAccept" style="cursor: pointer">
                                    <!--                        I Accept-->
                                    I Agree to Terms and Conditions
                                </label>
                            </div>
                            <div *ngIf="termsAccept.checked">
                                <ul class="primary-list gl-subtitle gl-subtitle--opacity">
                                    <li>Collection of {{aiUnits}} AI images</li>
                                    <li>{{unitsCost | currency}} in Sweeps Entries</li>
                                </ul>

<!--                                    <div style="margin-bottom: 2rem">-->
<!--                                        <div class="gl-subtitle"-->
<!--                                             *ngIf="isGenerateAll()"-->
<!--                                             style="opacity: 1">-->
<!--                                            {{'generateAllText' | languageTranslate}}-->
<!--                                            <ng-container *ngIf="showAdditionContent()">-->
<!--                                                {{'generateAllTextAddition' | languageTranslate}}-->
<!--                                            </ng-container>-->
<!--                                        </div>-->
<!--    -->
<!--                                        <ng-container *ngIf="!isGenerateAll()">-->
<!--                                            <div class="gl-subtitle"-->
<!--                                                 style="opacity: 1">-->
<!--                                                {{'generateManuallyText' | languageTranslate}}-->
<!--                                            </div>-->
<!--                                            <ng-container *ngIf="showAdditionContent()">-->
<!--                                                <div class="gl-subtitle accent"-->
<!--                                                     style="text-transform: uppercase; opacity: 1">-->
<!--                                                    {{'generateManuallyTextAddition' | languageTranslate}}-->
<!--                                                </div>-->
<!--                                                <button class="button"-->
<!--                                                        (click)="resetToGenerateAll()"-->
<!--                                                        [disabled]="isGenerateAll()"-->
<!--                                                        style="min-width: 4.5rem; width: 4.5rem">{{'yes' | languageTranslate}} </button>-->
<!--                                            </ng-container>-->
<!--                                        </ng-container>-->
<!--    -->
<!--                                    </div>-->
                            </div>
                        </form>
                    </ng-container>

                    <div class="balance-popup__footer" style="margin-top: 2rem;">
                        <button class="button balance-popup__sweep-btn"
                                *ngIf="depositStep === 1"
                                [disabled]="!unitsCost"
                                type="button"
                                (click)="goToSecondDepositStep()">
                            Continue
                        </button>
                        <button class="button"
                                style="margin-bottom: 1rem"
                                [disabled]="!balanceForm.controls.terms.value"
                                *ngIf="depositStep === 2"
                                (click)="onSubmit()">
                            charge
                        </button>
                    </div>
                </div>
                <ng-template #redeemTitle>
                    <div class="balance-popup__text" style="margin-bottom: 1rem;">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500
                    </div>
                    <div class="balance-popup__footer">
                        <button class="button balance-popup__sweep-btn" type="button" (click)="install()">
                            Install
                        </button>
                        <button class="button balance-popup__sweep-btn" type="button" (click)="closePopup()">
                            Cancel
                        </button>
                    </div>
                </ng-template>



<!--                <div class="balance-popup__form">-->
<!--                    <form [formGroup]="balanceForm"-->
<!--                          (ngSubmit)="onSubmitBalanceForm()"-->
<!--                          class="form-simple form-simple&#45;&#45;shrink form-simple&#45;&#45;primary">-->

<!--                        <div class="form-simple__item form-simple__item&#45;&#45;amount">-->
<!--                            <input mask="separator.0"-->
<!--                                   thousandSeparator=","-->
<!--                                   (input)="onInputAmount()"-->
<!--                                   formControlName="amount"-->
<!--                                   placeholder="Amount"-->
<!--                                   [type]="'tel'"-->
<!--                                   class="form-simple__input"-->
<!--                                   autocomplete="off">-->
<!--                            <span>.00</span>-->
<!--                            <div class="form-simple__available" [class.is-active]="moreThenAvailablePayout">-->
<!--                                Available {{popupInfo.balance | currency : 'USD' : 'symbol'}}-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="form-simple__btns"-->
<!--                             *ngIf="popupInfo.type !== POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp">-->
<!--                            <button class="button"-->
<!--                                    [disabled]="(+balanceForm.controls.amount.value <= 0) || !+popupInfo.balance">-->
<!--                                <ng-container *ngIf="popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit">-->
<!--                                    Add-->
<!--                                </ng-container>-->
<!--                                <ng-container *ngIf="popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.redeem">-->
<!--                                    Redeem-->
<!--                                </ng-container>-->
<!--                            </button>-->
<!--                        </div>-->

<!--                        <div class="form-simple__btns"-->
<!--                             *ngIf="popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.cashApp">-->
<!--                            <button class="button"-->
<!--                                    [disabled]="+balanceForm.controls.amount.value <= 0">-->
<!--                                CashApp Pay-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                </div>-->

                <div class="balance-popup__footer" *ngIf="popupInfo.type === POPUP_BALANCE_INCREASE_BALANCE_TYPE.deposit">
                    <button class="button balance-popup__sweep-btn"
                            type="button"
                            *ngIf="depositStep === 1"
                            (click)="closePopup()">
                        Cancel
                    </button>
                    <button class="button balance-popup__sweep-btn"
                            type="button"
                            *ngIf="depositStep === 2"
                            (click)="goToFirstDepositStep()">
                        Back
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngStyle]="{display: NFTPopupData ? 'block' : 'none'}">
    <app-charge-popup *ngIf="NFTPopupData"
                      [show]="showNFTPopup"
                      [popupData]="NFTPopupData.data"
                      (emitDepositConfirm)="onDepositConfirm($event)"
                      (close)="onPopupNFTClose()">
    </app-charge-popup>
</div>

<app-popup-wrapper [show]="isTermsExpandedShown" (onClose)="closeTermsExpanded($event)">
    <div class="investments-popup__scrolled" id="scrolled-wrapper">
        <div class="nft-terms" [innerHTML]="terms"></div>
    </div>
</app-popup-wrapper>
