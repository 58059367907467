import { TERMINAL_CONFIG } from "../config/api";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./loader.service";
import * as i3 from "./keyboard.service";
import * as i4 from "../shared/popup/popup.component";
import * as i5 from "@angular/router";
var TerminalService = /** @class */ (function () {
    function TerminalService(http, loaderService, keyboardService, popupComponent, router) {
        this.http = http;
        this.loaderService = loaderService;
        this.keyboardService = keyboardService;
        this.popupComponent = popupComponent;
        this.router = router;
        this.config = TERMINAL_CONFIG;
        this.isUserPlaying = false;
        this.isUserActivityIframe = new BehaviorSubject(null);
        this.isAppDevice();
    }
    TerminalService.prototype.checkLastUpdates = function () {
        return this.http.post(this.config.updatesLast, {});
    };
    TerminalService.prototype.detectUserActivity = function () {
        this.isUserActivityIframe.next(Math.round(+new Date() / 1000) + Math.random());
    };
    TerminalService.prototype.isAppDevice = function () {
        var userAgent = window.navigator.userAgent;
        var userAgent = userAgent.toLowerCase();
        if (userAgent.indexOf('myapp/kiosk') !== -1) {
            localStorage.setItem('isApp', '1');
        }
        else {
            localStorage.setItem('isApp', '0');
        }
        // if (environment.frontEndTest) {
        //     localStorage.setItem('isApp', '1');
        // }
    };
    TerminalService.prototype.isTerminal = function () {
        return !!Number(localStorage.getItem('isApp'));
    };
    TerminalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TerminalService_Factory() { return new TerminalService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService), i0.ɵɵinject(i3.KeyboardService), i0.ɵɵinject(i4.PopupComponent), i0.ɵɵinject(i5.Router)); }, token: TerminalService, providedIn: "root" });
    return TerminalService;
}());
export { TerminalService };
