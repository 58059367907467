<div *ngIf="popupData"
     class="investments-popup"
     [class.is-active]="popupData && show"
     [class.is-blurred]="false"
     [class.is-accent]="false">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()"></button>

        <div class="nft-info">
            <ng-template [ngIf]="popupData.payment === CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp">
                <p class="nft-terms__text" style="line-height: 1.2">
                    <!--                    Click-->
                    Click
                    <span style="font-family: b-montserrat, sans-serif">Cash App Pay</span>
                    button below to deposit.
                    <br>
                    <!--                    All sales are final.-->
                    All sales are final.
                </p>
            </ng-template>

            <div class="nft-info__btns">
                <div id="payment-form" *ngIf="popupData.payment === CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp">
                    <div id="cash-app-pay"></div>
                </div>
            </div>
        </div>
    </div>
</div>
