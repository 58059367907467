import { ElementRef, OnInit, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../modules/auth/_services/authentication.service";
import * as i3 from "../../service/login.service";
import * as i4 from "@angular/router";
import * as i5 from "../../service/loader.service";
import * as i6 from "../../service/keyboard.service";
import * as i7 from "../../service/rememberUser.service";
import * as i8 from "../../service/users/userBalance.service";
import * as i9 from "../../service/games/gamesSenet.service";
import * as i10 from "../../service/terminal.service";
import * as i11 from "../../service/screen-saver.service";
import * as i12 from "../../shared/popup/popup.component";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, authenticationService, loginService, router, auth, loaderService, keyboardService, rememberUserService, userBalanceService, gamesSenetService, terminalService, screenSaverService, popupComponent) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.authenticationService = authenticationService;
        this.loginService = loginService;
        this.router = router;
        this.auth = auth;
        this.loaderService = loaderService;
        this.keyboardService = keyboardService;
        this.rememberUserService = rememberUserService;
        this.userBalanceService = userBalanceService;
        this.gamesSenetService = gamesSenetService;
        this.terminalService = terminalService;
        this.screenSaverService = screenSaverService;
        this.popupComponent = popupComponent;
        this.isTerminal = !!Number(localStorage.getItem('isApp'));
        this.showEmailPopup = true;
        this.isPromoCodeRequire = false;
        this.logoutAfterMin = 2;
        this.logoutAfterSec = this.logoutAfterMin * 60;
        this.logoutAutoConfirmAfterSec = 20;
        this.isKeyboard = false;
        if (this.terminalService.isTerminal()) {
            this.detectActivity();
            this.setLogoutTimer();
            this.screenSaverService.setSaveScreenTimer();
            this.terminalService.isUserActivityIframe.subscribe(function (val) {
                if (val) {
                    _this.setLogoutTimer();
                }
            });
            // this.screenSaverService.logoutCmd.subscribe(val => {
            //     if (val) {
            //         this.logoutSinglePage();
            //     }
            // })
        }
        this.keyboardService.isKeyboardVisible.subscribe(function (value) {
            _this.isKeyboard = value;
        });
        this.authenticationService.userLoggedStatusChanged.subscribe(function (val) {
            if (val) {
            }
            else {
                _this.showEmailPopup = true;
                _this.emailForm = _this.formBuilder.group({
                    email: [null, Validators.required],
                });
                _this.promoCodeForm = _this.formBuilder.group({
                    promoCode: [null],
                });
                _this.userBalanceService.closePopup();
                _this.promoCodeForm.reset();
                if (_this.terminalService.isTerminal()) {
                    _this.setLogoutTimer();
                    _this.screenSaverService.setSaveScreenTimer();
                }
                if (_this.isKeyboard) {
                    _this.keyboardService.showKeyboard();
                }
                setTimeout(function () {
                    // this.inputs.toArray()[0].nativeElement.focus();
                    document.querySelector('input').focus();
                    if (_this.isKeyboard) {
                        _this.keyboardService.setActiveInput();
                    }
                }, 0);
            }
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.isTerminal = this.terminalService.isTerminal();
        this.auth.logout();
        this.showEmailPopup = true;
        this.emailForm = this.formBuilder.group({
            email: [null, Validators.required]
        });
        this.promoCodeForm = this.formBuilder.group({
            promoCode: [null, Validators.required]
        });
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.clearSaveScreenTimer();
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
            }
        }
    };
    LoginComponent.prototype.detectActivity = function () {
        var _this = this;
        setTimeout(function () {
            document.addEventListener('scroll', _this.onUserActivity.bind(_this));
            document.addEventListener('touchstart', _this.onUserActivity.bind(_this));
            document.addEventListener('click', _this.onUserActivity.bind(_this));
            document.addEventListener('keydown', _this.onUserActivity.bind(_this));
        });
    };
    LoginComponent.prototype.onUserActivity = function () {
        var _this = this;
        this.setLogoutTimer();
        if (this.showEmailPopup) {
            this.screenSaverService.hideScreenSaver();
            setTimeout(function () {
                document.querySelector('input').focus();
                if (_this.isKeyboard) {
                    _this.keyboardService.setActiveInput();
                }
            }, 0);
        }
        else {
            this.screenSaverService.clearSaveScreenTimer();
        }
    };
    LoginComponent.prototype.setLogoutTimer = function () {
        var _this = this;
        if (this.terminalService.isTerminal()) {
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
                clearInterval(this.logoutInterval);
            }
            this.logoutTimeout = setTimeout(function () {
                if (_this.showEmailPopup) {
                    if (_this.emailForm.controls.email.value) {
                        _this.emailForm.controls.email.setValue('');
                        _this.popupComponent.closePopup();
                        _this.screenSaverService.setSaveScreenTimer();
                    }
                    // this.updateLogoutTimer();
                    _this.setLogoutTimer();
                }
                else if (_this.terminalService.isUserPlaying) {
                    _this.terminalService.checkLastUpdates().subscribe(function (data) {
                        var now = data.now;
                        var last = data.last;
                        if ((now - last) > _this.logoutAfterSec) {
                            _this.logout();
                        }
                    }, function () {
                        _this.loaderService.hideAndShowTryAgain();
                    }, function () {
                        // this.updateLogoutTimer();
                        _this.setLogoutTimer();
                    });
                }
                else {
                    _this.logout();
                }
            }, this.logoutAfterSec * 1000);
            // if (this.logoutTimeout) {
            //     let count = 0;
            //     this.logoutInterval = setInterval(() => {
            //         count += 1;
            //         console.log(this.logoutTimeout, count)
            //     }, 1000);
            // }
        }
    };
    LoginComponent.prototype.logout = function () {
        var _this = this;
        var counter = this.logoutAutoConfirmAfterSec;
        var counterInterval = setInterval(function () {
            if (counter) {
                counter -= 1;
                document.querySelector('#logoutTimer').innerHTML = String(counter) + '.';
            }
            else {
                clearInterval(counterInterval);
            }
        }, 1000);
        var logoutTime = this.logoutAfterMin === 5 ? 'five (5)' : this.logoutAfterMin === 2 ? 'two (2)' : '';
        this.popupComponent.showPopupAgreement({ text: "Are you still here? No activity is detected for " + logoutTime + " minutes. We are about to log you out in <span id=\"logoutTimer\">" + counter + "</span>", style: 'swal-agreement no-close-btn btn-continue' }, function (result) {
            if (result === 'timerOut') {
                _this.logoutSinglePage();
            }
            clearInterval(counterInterval);
            _this.setLogoutTimer();
            _this.screenSaverService.setSaveScreenTimer();
        }, this.logoutAutoConfirmAfterSec * 1000);
    };
    LoginComponent.prototype.logoutSinglePage = function () {
        var _this = this;
        this.popupComponent.closePopup();
        this.terminalService.isUserPlaying = false;
        this.showEmailPopup = true;
        this.isPromoCodeRequire = false;
        if (this.promoCodeForm && this.emailForm) {
            this.emailForm.controls.email.setValue('');
            this.promoCodeForm.reset();
        }
        this.authenticationService.logout();
        this.userBalanceService.closePopup();
        setTimeout(function () {
            // this.inputs.toArray()[0].nativeElement.focus();
            document.querySelector('input').focus();
            if (_this.isKeyboard) {
                _this.keyboardService.setActiveInput();
            }
        }, 0);
    };
    LoginComponent.prototype.updateLogoutTimer = function () {
        var _this = this;
        console.log('updateLogoutTimer : sliders');
        this.logoutTimeout = setTimeout(function () {
            _this.setLogoutTimer();
        }, this.logoutAfterSec * 1000);
    };
    LoginComponent.prototype.onEmailSubmit = function () {
        var _this = this;
        if (this.emailForm.invalid) {
            return;
        }
        var email = this.emailForm.controls.email.value;
        this.authenticationService.sendEmail(email).subscribe(function (data) {
            // data.status = false;
            // data.promoCode = 1;
            try {
                if (data.status) {
                    if (data.promoCode) {
                        _this.showEmailPopup = false;
                        _this.isPromoCodeRequire = true;
                        setTimeout(function () {
                            document.querySelector('input').focus();
                            if (_this.isKeyboard) {
                                _this.keyboardService.setActiveInput();
                            }
                        }, 0);
                        if (_this.terminalService.isTerminal()) {
                            _this.screenSaverService.clearSaveScreenTimer();
                        }
                    }
                    else {
                        _this.checkLoginSubmit(data);
                    }
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.prototype.onRegisterNewUser = function () {
        var _this = this;
        var email = this.emailForm.controls.email.value;
        var promoCode = this.promoCodeForm.controls.promoCode.value;
        if (promoCode) {
            promoCode = String(promoCode).toLowerCase();
        }
        this.authenticationService.register(email, promoCode).subscribe(function (data) {
            try {
                if (data.status) {
                    _this.checkLoginSubmit(data);
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    LoginComponent.prototype.checkLoginSubmit = function (data) {
        try {
            if (data.status) {
                this.createSenetGamesSession();
                this.showEmailPopup = false;
                this.isPromoCodeRequire = false;
                if (this.isKeyboard) {
                    this.keyboardService.keyboardVisible.next(false);
                }
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
                //this.popupGoBack();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
            //this.popupGoBack();
        }
    };
    LoginComponent.prototype.createSenetGamesSession = function () {
        this.gamesSenetService.getSenetSessionRequest();
    };
    LoginComponent.prototype.popupGoBack = function () {
        var _this = this;
        this.showEmailPopup = true;
        this.isPromoCodeRequire = false;
        this.promoCodeForm.reset();
        if (this.terminalService.isTerminal()) {
            this.screenSaverService.setSaveScreenTimer();
        }
        setTimeout(function () {
            document.querySelector('input').focus();
            if (_this.isKeyboard) {
                _this.keyboardService.setActiveInput();
            }
        }, 1000);
    };
    LoginComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginComponent_Factory() { return new LoginComponent(i0.ɵɵinject(i1.FormBuilder), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.LoginService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i5.LoaderService), i0.ɵɵinject(i6.KeyboardService), i0.ɵɵinject(i7.RememberUserService), i0.ɵɵinject(i8.UserBalanceService), i0.ɵɵinject(i9.GamesSenetService), i0.ɵɵinject(i10.TerminalService), i0.ɵɵinject(i11.ScreenSaverService), i0.ɵɵinject(i12.PopupComponent)); }, token: LoginComponent, providedIn: "root" });
    return LoginComponent;
}());
export { LoginComponent };
