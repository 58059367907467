<div class="bg is-blurred"></div>
<div class="login">
    <div class="login__inner">
        <div class="popup popup-login"  *ngIf="showEmailPopup || isPromoCodeRequire">
            <div class="popup-login__inner"
                 [class.has-promo]="isPromoCodeRequire">
                <div class="popup__content"></div>
                <form *ngIf="showEmailPopup" [formGroup]="emailForm"
                      [class.has-promo]="isPromoCodeRequire"
                      (ngSubmit)="onEmailSubmit()" class="popup-login__form">
                    <div class="popup-login__item">
                        <input formControlName="email"
                               placeholder="Email"
                               [email]="true"
                               (keydown.enter)="onEmailSubmit()"
                               [type]="'text'"
                               class="login-input js-phone-input input-bordered-mascot"
                               autocomplete="off">
                    </div>

                    <button class="popup-login__btn popup-login__btn--login" [disabled]="!emailForm?.valid"></button>
                </form>

                <form *ngIf="isPromoCodeRequire" [formGroup]="promoCodeForm" class="popup-login__form">

                    <div class="login__item"
                         style="margin-bottom: 2rem"
                         *ngIf="isPromoCodeRequire">
                        <input formControlName="promoCode"
                               style="text-transform: lowercase"
                               #promoCode
                               [mask]="'A*'"
                               placeholder="Promo Code*"
                               [type]="'text'"
                               class="login-input"
                               autocomplete="off">
                    </div>

                    <button (click)="popupGoBack()" type="button" class="text t-light login-text login__resend">Back</button>

                    <button (click)="onRegisterNewUser()"
                            class="popup-login__btn popup-login__btn--login"
                            [disabled]="promoCodeForm.invalid || !promoCodeForm.controls.promoCode.value"></button>
                </form>
            </div>
        </div>
    </div>
</div>
